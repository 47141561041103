
  import {Component, Prop, Vue} from 'vue-property-decorator';
  import MaterialProgress from '@/views/material/MaterialProgress.vue';
  import {Content, EmptyContent} from '@/types/material/Content';
  import ProgressStatusTag from '@/components/atoms/ProgressStatusTag.vue';
  import moment from 'moment';
  import ContentThumbnail from '@/components/atoms/ContentThumbnail.vue';
  import {ProgressStatus} from '@/types/content/ProgressStatus';

  @Component({
    components: {MaterialProgress, ProgressStatusTag, ContentThumbnail},
    filters: {
      timeFormat(time: string): string {
        if (!time) {
          return '00分00秒';
        }
        const momentTime = moment(time, 'HH:mm:ss');
        return momentTime.format('mm分ss秒');
      },
    },
  })
  export default class ContentSummary extends Vue {
    @Prop({default: EmptyContent})
    content!: Content;

    @Prop({default: false})
    isPrevMaterialLeaned!: boolean;

    // computed
    get isTestable(): boolean {
      return this.content.hasTest && (
        this.content.status === ProgressStatus.Testing ||
        this.content.status === ProgressStatus.Learned
      );
    }

    // methods
    toTest(): void {
      this.$router.push({path: `/content/${this.content.id}/test`}).catch(() => {
        // @ts-ignore block is empty.
      });
    }

    // method
    toContentDetail(): void {
      if (this.content.isViewable && this.isPrevMaterialLeaned) {
        this.$router.push({path: `/content/${this.content.id}`}).catch(() => {
          // @ts-ignore block is empty.
        });
      }
    }
  }
