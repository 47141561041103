
import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import AnswerRadioGroup from '@/views/material/TestQuestionAnswerRadioGroup.vue';
import {EmptyTestAnswer, getCorrectAnswer, Question, TestAnswer} from '@/types/content/Question';
import api from '@/infrastructure/api/API';
import {ContentExamAnswerGetRequest, ContentGetRequest} from '@/types/material/Content';
import {ViewContentRegisterRequest} from '@/types/content/ViewContent';
import {ContentExamResultRegisterRequest} from '@/types/content/ContentExams';
import moment from 'moment';
import {progress} from '@/infrastructure/script/Progress';
@Component({
  components: {
    AnswerRadioGroup,
  },
})
export default class TestQuestion extends Vue {
  // prop
  @Prop()
  index!: number;
  @Prop()
  question!: Question;

  // data
  request: ContentExamAnswerGetRequest | null = null;
  answers: TestAnswer[] = [EmptyTestAnswer];
  selectedAnswer: TestAnswer = EmptyTestAnswer;
  answered: boolean = false;
  answerAt  = moment().toDate();

  // computed
  get isSelected(): boolean {
    return this.selectedAnswer.label !== '';
  }

  get isAnswered(): boolean {
    return this.answered;
  }

  get hasThumbnail(): boolean {
    if (!this.question) {
      return false;
    }
    return !!this.question.imageFileURL;
  }

  get imagePath(): string {
    if (this.isAnswered && this.question.exampleImageFileURL) {
      return this.question.exampleImageFileURL;
    }
    if (this.question.imageFileURL) {
      return this.question.imageFileURL;
    }
    return '';
  }

  get resultLabel(): string {
    const correct = this.correct(this.answers);
    if (correct.label === this.selectedAnswer.label) {
      return '正解！！';
    }
    return '不正解！';
  }

  get correctLabel(): string {
    const correct = this.correct(this.answers);
    return correct.label;
  }

  get answerLabel(): string {
    return this.selectedAnswer.label;
  }

  // watch
  @Watch('index')
  async onChangeQuestion() {
    this.answers = await this.getAnswers();
    this.question.answers = await this.getAnswers();
    this.selectedAnswer = EmptyTestAnswer;
    this.answered = false;
  }

  // method
  async getAnswers(): Promise<TestAnswer[]> {
    this.request = {
      // @ts-ignore
      contentExamId: this.question.id,
    };
    const contentExamAnswers = await api.getContentExamAnswer(this.request);
    return contentExamAnswers.answers;
  }


  correct(answers: TestAnswer[]): TestAnswer {
    return getCorrectAnswer(answers);
  }

  select(answer: TestAnswer): void {
    this.selectedAnswer = answer;
  }

  async answer(): Promise<void> {
    const answerTask = async (): Promise<void> => {
      const request = {
        // @ts-ignore
        contentExamId: this.question.id,
        contentExamAnswerId: this.selectedAnswer.id,
        answerAt: this.answerAt,
      } as ContentExamResultRegisterRequest;
      await api.registerContentExamResult(request)
          .catch((error: any) => {
            if (error.data.errors) {
              return;
            }
          });
    };
    await progress(answerTask);
    this.answered = true;
  }

  @Emit('done')
  done(): TestAnswer {
    return this.selectedAnswer;
  }

  // lifecycle hooks
  async created(): Promise<void> {
    this.answers = await this.getAnswers();
    this.question.answers = await this.getAnswers();
  }
}
