
  import {Component, Prop, Vue} from 'vue-property-decorator';
  import {ProgressStatus} from '@/types/content/ProgressStatus';

  @Component({})
  export default class ProgressStatusTag extends Vue {

    @Prop({default: ProgressStatus.Unlearned})
    status!: ProgressStatus;

    // computed
    get isLearning(): boolean {
      return this.status === ProgressStatus.Learning;
    }

    get isTesting(): boolean {
      return this.status === ProgressStatus.Testing;
    }

    get isLearned(): boolean {
      return this.status === ProgressStatus.Learned;
    }

    get isExemption(): boolean {
      return this.status === ProgressStatus.Exemption;
    }
  }
