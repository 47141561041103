
import {Component, Vue} from 'vue-property-decorator';
import MaterialProgress from '@/views/material/MaterialProgress.vue';
import {Content, ContentGetRequest, EmptyContent} from '@/types/material/Content';
import api from '@/infrastructure/api/API';
import {progress} from '@/infrastructure/script/Progress';
import {Question, TestAnswer} from '@/types/content/Question';
import TestQuestion from '@/views/material/TestQuestion.vue';
import TestResults from '@/views/material/TestResults.vue';
import {TestContentRegisterRequest} from '@/types/content/TestContent';
import {ContentExams} from '@/types/content/ContentExams';

@Component({
  components: {
    MaterialProgress,
    TestQuestion,
    TestResults,
  },
})
export default class TestDetail extends Vue {
  // data
  content: Content = EmptyContent;
  request: ContentGetRequest | null = null;

  questions: Question[] = [];
  answers: TestAnswer[] = [];
  current: number = 0;

  // computed
  get isCompleted(): boolean {
    return this.questions.length === this.answers.length;
  }

  get currentIndex(): number {
    return this.current;
  }

  get subtitle(): string {
    if (this.isCompleted) {
      return this.content.name + '　小テスト結果';
    }
    return this.content.name + '　小テスト';
  }

  get correctCount(): number {
    const count = this.answers.reduce((prev, item) => {
      return prev + (item.correct ? 1 : 0);
    }, 0);
    return count;
  }

  // methods
  async initialize(): Promise<void> {
    await this.getContent();
    const contentExams = await this.getQuestions();
    this.questions = contentExams.exams;
    this.answers = [];
    this.current = 0;
  }

  async getQuestions(): Promise<ContentExams> {
    // @ts-ignore
    const contentId = Number(this.$route.params.id);
    if (!this.request) {
      this.request = {
        // @ts-ignore
        contentId: this.$route.params.id as number,
      };
    }
    return  await api.getContentExam(this.request);
  }

  async getContent(): Promise<void> {
    const getContent = async (): Promise<void> => {
      if (!this.request) {
        return;
      }
      await api.getContent(this.request)
        .then((response: any) => {
          this.content = response;
        });
    };

    await progress(getContent);
  }

  answered(answer: TestAnswer): void {
    this.answers.push(answer);
    if (this.isCompleted) {
      this.registerTestContent();
      return;
    }
    this.current++;
  }

  async registerTestContent(): Promise<void> {
    const testedContent = async (): Promise<void> => {
      const request = {
        contentId: this.content.id,
      } as TestContentRegisterRequest;
      await api.registerTestContent(request)
        .catch((error: any) => {
          // TODO エラー
          if (error.data.errors) {
            return;
          }
        });
    };
    await progress(testedContent);
  }

  toMyPage(): void {
    this.$router.push({path: '/'}).catch(() => {
      // @ts-ignore block is empty.
    });
  }

  // lifecycle hooks
  created(): void {
    this.request = {
      // @ts-ignore
      contentId: this.$route.params.id as number,
    };
    this.initialize();
  }
}
