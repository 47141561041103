
import {Component, Prop, Vue} from 'vue-property-decorator';
import {EmptyMaterial, Material} from '@/types/material/Material';
import {ProgressStatus} from '@/types/content/ProgressStatus';
import ProgressStatusGuideLine from '@/components/atoms/ProgressStatusGuideLine.vue';
import ProgressStatusTag from '@/components/atoms/ProgressStatusTag.vue';
import ContentSummary from '@/views/material/ContentSummary.vue';
import {Content} from '@/types/material/Content';

@Component({
  components: {
    ProgressStatusGuideLine,
    ProgressStatusTag,
    ContentSummary,
  },
})
export default class MaterialProgress extends Vue {
  @Prop({default: EmptyMaterial})
  material!: Material;
  @Prop({default: false})
  linkable!: boolean;
  @Prop({default: true})
  showContentSummary!: boolean;
  @Prop({})
  prevMaterialStatus!: string;


  // computed
  get isDone(): boolean {
    if (!this.material) {
      return false;
    }
    return this.material.statuses.every((status) => {
      return (status === ProgressStatus.Learned || status === ProgressStatus.Exemption);
    });
  }

  get isAllUnlearned(): boolean {
    if (!this.material) {
      return false;
    }
    return this.material.statuses.every((status) => {
      return (status === ProgressStatus.Unlearned);
    });
  }

  get toMaterialLabel(): string {
    if (this.isDone) {
      return '復習する';
    }
    return '学習する';
  }

  isPrevMaterialLeaned(stat: string): boolean {
    return stat === ProgressStatus.Learned;
  }

  // method
  toMaterial(id: number): void {
    this.$router.push({path: `/material/${id}`}).catch(() => {
      // @ts-ignore block is empty.
    });
  }
  toContentDetail(content: Content): void {
    if (content.isViewable) {
      this.$router.push({path: `/content/${content.id}`}).catch(() => {
        // @ts-ignore block is empty.
      });
    }
  }
}
