
  import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
  import {TestAnswer} from '@/types/content/Question';

  @Component({})
  export default class TestQuestionAnswerRadioGroup extends Vue {
    // prop
    @Prop()
    answers!: TestAnswer[];
    @Prop()
    disabled!: boolean;

    // data
    value: string = '';

    // watch
    @Watch('answers', {deep: true})
    onChangeAnswers() {
      this.value = '';
    }

    // emit
    @Emit('select')
    select(answer: TestAnswer): TestAnswer {
      return answer;
    }
  }
