
import {Component, Prop, Vue} from 'vue-property-decorator';
import {ProgressStatus} from '@/types/content/ProgressStatus';

@Component({})
export default class ProgressStatusGuideLine extends Vue {

  @Prop({default: ProgressStatus.Unlearned})
  status!: ProgressStatus;
  @Prop({default: false})
  allUnlearned!: boolean;

  // computed
  get guideLine(): string {
    if (this.allUnlearned) {
      return '動画の視聴を開始してください';
    }
    if (this.status === ProgressStatus.Learning) {
      return '動画視聴が途中です、学習を再開してください';
    }
    if (this.status === ProgressStatus.Testing) {
      return '小テストを受けて次の動画学習へ進んでください';
    }
    if (this.status === ProgressStatus.Learned) {
      return '教材学習修了しました';
    }
    return '動画視聴が途中です、学習を再開してください';
  }

  get guideLineClass(): string {
    if (this.allUnlearned) {
      return 'n';
    }
    if (this.status === ProgressStatus.Learning) {
      return 'p';
    }
    if (this.status === ProgressStatus.Testing) {
      return 't';
    }
    if (this.status === ProgressStatus.Learned) {
      return 'c';
    }
    return 'p';
  }
}
