export interface Question {
  id: number;
  no: number;
  headLine: string;
  introduction: string;
  text: string;
  answers: TestAnswer[];
  description: string;
  imageFileURL: string | null;
  exampleImageFileURL: string | null;
}

export interface TestAnswer {
  id: number;
  no: number;
  text: string;
  label: string;
  correct: boolean;
}

export const EmptyTestAnswer: TestAnswer = {
  id: 0,
  no: 0,
  text: '',
  label: '',
  correct: false,
};

export function getCorrectAnswer(answers: TestAnswer[]): TestAnswer {
  return answers.find((answer) => answer.correct) as TestAnswer;
}
