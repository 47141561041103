import {ProgressStatus} from '@/types/content/ProgressStatus';
import {EmptyMaterial, Material} from '@/types/material/Material';

export interface Content {
  id: number;
  name: string;
  description: string;
  sourceURL: string;
  thumbnailURL: string;
  recordingTime: string;
  hasTest: boolean;
  status: ProgressStatus;
  latestTimeSeconds: number;
  isViewable: boolean;
  material: Material | null;
}

export const EmptyContent: Content = {
  id: 0,
  name: '',
  description: '',
  sourceURL: '',
  thumbnailURL: '',
  recordingTime: '',
  hasTest: false,
  status: ProgressStatus.Unlearned,
  latestTimeSeconds: 0,
  isViewable: false,
  material: EmptyMaterial,
};

export interface ContentGetRequest {
  contentId: number;
}
export interface ContentExamAnswerGetRequest {
  contentExamId: number;
}

export const DummySourceURL: string = 'https://jaai-media-convert-dev.s3-ap-northeast-1.amazonaws.com/el01_ppt01_001.m3u8';
