
  import {Component, Prop, Vue} from 'vue-property-decorator';
  import {getCorrectAnswer, Question, TestAnswer} from '@/types/content/Question';

  @Component
  export default class TestResults extends Vue {
    // prop
    @Prop()
    questions!: Question[];
    @Prop()
    answers!: TestAnswer[];

    // method
    isCorrectAnswer(index: number): boolean {
      const question = this.questions[index];
      const answer = this.answers[index];
      return this.correct(question.answers).label === answer.label;

    }

    correct(answers: TestAnswer[]): TestAnswer {
      return getCorrectAnswer(answers);
    }

    correctLabel(index: number): string {
      const question = this.questions[index];
      return this.correct(question.answers).label;
    }
  }
